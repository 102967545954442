import '../styles/index.scss';

const axios = require('axios').default;
const imageHash = "OrnWN9k";
const albumHash = "WJdXCw0";
const CLIENT_ID = '69d566a67892024';
//const single_image_url = 'https://api.imgur.com/3/image/' + imageHash;
const album_url = `https://api.imgur.com/3/album/${albumHash}/images`;
let imagesArray = [];
let firstClick = true;

function getData() {
    return axios({
        method: 'get',
        url: album_url,
        headers: {
            'Authorization': 'Client-ID 69d566a67892024'
        }
    })
        .catch(function (error) {
            console.log(error);
        });
}

async function TossCat() {
    const images = await getData();
    return images.data;
}

TossCat().then(res => {
    for (const i in res.data) {
        imagesArray.push(res.data[i].link);

    }

});

function showCat() {
    if (firstClick) {
        let img = document.createElement("img");
        let imageNumber = Math.floor(Math.random() * imagesArray.length);
        img.src = imagesArray[imageNumber];
        img.classList.add("image");
        let div = document.querySelector(".parent");
        div.appendChild(img);
        firstClick = false;
    } else {
        let img = document.createElement("img");
        let imageNumber = Math.floor(Math.random() * imagesArray.length);
        img.src = imagesArray[imageNumber];
        img.classList.add("image");
        let div = document.querySelector(".parent");
        while (div.firstChild) {
            div.removeChild(div.firstChild);
        }
        div.appendChild(img);
    }

}


let button = document.querySelector(".random-button");
button.addEventListener('click', showCat);


let row_delete = document.querySelector(".dupa");
function closeInfo() {
    row_delete.removeChild(row_delete.firstElementChild);
}

let close_button = document.querySelector(".close-button");
close_button.addEventListener('click', closeInfo);